<template>
  <div>
    <div class="container">
      <div class="card mb-8">
        <div class="card-body p-10">
          <div class="row">
            <div class="col-lg-3">
              <ul class="navi navi-link-rounded navi-accent navi-hover navi-active nav flex-column mb-8 mb-lg-0" role="tablist">
                <li v-for="topic in computedTopics" :key="topic.id" class="navi-item cursor-pointer mb-2">
                  <div
                      class="d-flex flex-column align-items-start navi-link"
                      :class="{'active': topic.id === activeTopic}"
                      data-toggle="tab"
                      @click="toggleTopic(topic.id)"
                  >
                    <span class="navi-text text-dark-75 font-size-h6 font-weight-bold">{{ topic.title }}</span>
                    <span v-if="topic.title !== topic.description" class="d-block text-sm text-dark-50 mt-2">{{ topic.description }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-9">
              <div class="tab-content">
                <div class="accordion accordion-light accordion-light-borderless accordion-svg-toggle">
                  <div v-for="article in computedArticles" :key="article.id" class="card">
                    <div class="card-header">
                      <div
                          class="card-title text-dark"
                          :class="{'collapsed': article.id !== activeArticle}"
                          data-toggle="collapse"
                          @click="toggleArticle(article.id)"
                      >
                        <span class="svg-icon svg-icon-primary">
                          <inline-svg src="media/svg/icons/Navigation/Angle-double-right.svg"/>
                        </span>
                        <div class="card-label text-dark pl-4">{{ article.title }}</div>
                      </div>
                    </div>
                    <div class="collapse" :class="{'show': article.id === activeArticle}">
                      <div v-if="apiArticle" class="card-body pl-12">
                        <div class="border border-primary rounded-lg p-3">{{ apiArticle.description }}</div>

                        <b-card no-body class="mt-7">
                          <b-tabs pills card content-class="mt-5 pl-5" nav-class="pl-5">
                            <b-tab title="Web" active><b-card-text v-html="apiArticle.web"></b-card-text></b-tab>
                            <b-tab title="Mobile web"><b-card-text v-html="apiArticle.mobile_web"></b-card-text></b-tab>
                            <b-tab title="App"><b-card-text v-html="apiArticle.app"></b-card-text></b-tab>
                          </b-tabs>
                        </b-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Help',
  data: () => ({
    activeTopic: null,
    activeArticle: null
  }),
  mounted() {
    this.$store.dispatch('helpStore/GET_TOPICS');
  },
  computed: {
    ...mapGetters({
      topics: 'helpStore/TOPICS',
      apiArticle: 'helpStore/ARTICLE'
    }),
    computedTopics() {
      return this.topics.filter(el => el.articles.length);
    },
    computedArticles() {
      return this.topics.length && this.activeTopic ? this.topics.find(el => el.id === this.activeTopic).articles : [];
    }
  },
  watch: {
    computedTopics(data) {
      if (data.length) {
        this.activeTopic = data[0]['id'];
      }
    },
    activeArticle(id) {
      if (id) {
        this.$store.dispatch('helpStore/GET_ARTICLE', id);
      } else {
        this.$store.commit('helpStore/CLEAR_ARTICLE');
      }
    }
  },
  methods: {
    toggleArticle(id) {
      this.activeArticle = this.activeArticle !== id ? id : null;
    },
    toggleTopic(id) {
      this.activeTopic = id;
      this.activeArticle = null;
    }
  }
}
</script>
